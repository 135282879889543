import { AppBar, useScrollTrigger } from "@mui/material";
import PropTypes from "prop-types";
import React, { cloneElement } from "react";

import { ControlBar, NavBar } from "..";

const ElevationScroll = ({ children, controlBarHeight, darkMode, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
    threshold: controlBarHeight,
  });
  return cloneElement(children, {
    elevation: trigger && !darkMode ? 4 : 0,
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  controlBarHeight: PropTypes.number.isRequired,
  darkMode: PropTypes.bool.isRequired,
  window: PropTypes.func,
};

const Header = ({
  darkMode,
  toggleDarkMode,
  controlBarRef,
  controlBarHeight,
  appBarRef,
  drawerOpen,
  setDrawerOpen,
}) => (
  <>
    <ControlBar
      ref={controlBarRef}
      darkMode={darkMode}
      onToggleDarkMode={toggleDarkMode}
    />
    <ElevationScroll controlBarHeight={controlBarHeight} darkMode={darkMode}>
      <AppBar
        ref={appBarRef}
        color="inherit"
        elevation={0}
        position="sticky"
        sx={{ display: "block" }}
      >
        <NavBar
          drawerOpen={drawerOpen}
          onToggleDrawer={() => setDrawerOpen((prevState) => !prevState)}
        />
      </AppBar>
    </ElevationScroll>
  </>
);

Header.propTypes = {
  appBarRef: PropTypes.object,
  controlBarHeight: PropTypes.number.isRequired,
  controlBarRef: PropTypes.object,
  darkMode: PropTypes.bool.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};

export default Header;
