import { Link, SvgIcon, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const IconView = ({ icon, message, title }) => (
  <div className="outer">
    <div className="inner">
      <SvgIcon
        inheritViewBox
        component={icon}
        sx={{
          height: "100%",
          maxHeight: {
            md: 320,
            sm: 256,
            xs: 192,
          },
          width: "100%",
        }}
        variant="filter"
      />
      <Typography
        gutterBottom
        align="center"
        sx={{ fontWeight: "bolder" }}
        variant="h3"
      >
        {title}
      </Typography>
      <Typography gutterBottom align="center" sx={{ color: "text.secondary" }}>
        {message}
      </Typography>
      <Typography align="center" sx={{ color: "text.secondary" }}>
        You can start again from the{" "}
        <Link
          href="/"
          sx={{
            display: "inline",
          }}
          variant="colorPrimary"
        >
          home page
        </Link>{" "}
        or go back to the previous page.
      </Typography>
    </div>
  </div>
);

IconView.propTypes = {
  icon: PropTypes.elementType.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default IconView;
