import { useTheme } from "@emotion/react";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Collapse,
  Drawer as MuiDrawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
} from "@mui/material";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { uid } from "react-uid";

import { AegisIcon } from "../../assets";
import { publicRoutes } from "../../configs/router/publicRoutes";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  "&.active, &:hover": {
    ".MuiTypography-root": {
      color: theme.palette.text.primary,
      fontWeight: 425,
    },

    borderLeft: `solid 3px ${theme.palette.primary.main}`,
  },
}));

const DrawerHeader = () => (
  <List disablePadding>
    <ListItem disableGutters component="div">
      <ListItemIcon>
        <AegisIcon sx={{ fontSize: 40 }} />
      </ListItemIcon>
      <ListItemText
        primary="Aegis Cyber"
        primaryTypographyProps={{
          variant: "h6",
        }}
      />
    </ListItem>
  </List>
);

const ContactLink = ({ id, path, setOpen }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const primaryColor = isDarkMode
    ? theme.palette.primary.main
    : theme.palette.primary.light;
  const secondaryColor = isDarkMode
    ? theme.palette.secondary.main
    : theme.palette.secondary.light;

  return (
    <motion.div
      animate={{
        background: [
          `linear-gradient(to right, ${primaryColor} -200%, ${secondaryColor} -100%, ${primaryColor} 0%, ${secondaryColor} 100%)`,
          `linear-gradient(to right, ${primaryColor} -100%, ${secondaryColor} 0%, ${primaryColor} 100%, ${secondaryColor} 200%)`,
          `linear-gradient(to right, ${primaryColor} 0%, ${secondaryColor} 100%, ${primaryColor} 200%, ${secondaryColor} 300%)`,
        ],
      }}
      transition={{
        duration: 2,
        ease: "linear",
        repeat: Infinity,
      }}
    >
      <ListItemButton
        component={Link}
        href={path}
        onClick={() => setOpen((prev) => !prev)}
      >
        <ListItemText
          primary={id}
          primaryTypographyProps={{
            color: "text.primary",
            fontWeight: "bold",
            variant: "subtitle1",
          }}
        />
      </ListItemButton>
    </motion.div>
  );
};

ContactLink.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const PopupLink = ({ route, setOpen }) => {
  const location = useLocation();
  const match = useMatch(route);

  const [listOpen, setListOpen] = useState(false);

  const isActiveRoute = (route, match, location) =>
    match
      ? match.pattern.path === route.path
      : location.pathname.includes(route.path);

  return (
    <>
      <StyledListItemButton
        className={isActiveRoute(route, match, location) ? "active" : ""}
        component="div"
        onClick={() => setListOpen((prev) => !prev)}
      >
        <ListItemText
          primary={route.id}
          primaryTypographyProps={{
            color: "text.secondary",
            variant: "subtitle1",
          }}
        />
        <ListItemIcon sx={{ justifyContent: "flex-end" }}>
          {listOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </StyledListItemButton>
      <Collapse unmountOnExit in={listOpen} timeout="auto">
        <List disablePadding>
          {(route.patterns || route.children).map(({ id, path }) => (
            <React.Fragment key={uid({ id, path })}>
              <RegularLink
                id={id}
                path={`${route.path}/${path}`}
                setOpen={setOpen}
              />
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </>
  );
};

PopupLink.propTypes = {
  route: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    patterns: PropTypes.array,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
};

const RegularLink = ({ id, path, setOpen }) => (
  <StyledListItemButton
    component={Link}
    href={path}
    onClick={() => setOpen((prev) => !prev)}
  >
    <ListItemText
      primary={id}
      primaryTypographyProps={{
        color: "text.secondary",
        variant: "subtitle1",
      }}
    />
  </StyledListItemButton>
);

RegularLink.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const DrawerLink = ({ route, setOpen }) =>
  route.children || route.patterns ? (
    <PopupLink route={route} setOpen={setOpen} />
  ) : route.path === "contact" ? (
    <ContactLink id={route.id} path={route.path} setOpen={setOpen} />
  ) : (
    <RegularLink id={route.id} path={route.path} setOpen={setOpen} />
  );

DrawerLink.propTypes = {
  route: PropTypes.shape({
    children: PropTypes.array,
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    patterns: PropTypes.array,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
};

const Drawer = ({ open, setOpen }) => (
  <MuiDrawer
    ModalProps={{
      keepMounted: true,
      onClose: () => setOpen(false),
    }}
    component="nav"
    open={open}
    sx={{
      "& .MuiDrawer-paper": {
        backgroundImage: "none",
        width: 256,
      },

      display: { sm: "none", xs: "block" },
    }}
    variant="temporary"
  >
    <List
      subheader={
        <ListSubheader>
          <DrawerHeader />
        </ListSubheader>
      }
    >
      {publicRoutes
        .filter((route) => route.id != null)
        .map((route) => (
          <DrawerLink key={uid(route)} route={route} setOpen={setOpen} />
        ))}
    </List>
  </MuiDrawer>
);

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Drawer;
