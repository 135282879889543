import { Stack, styled, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

import { socialMedias } from "../../models";
import { DarkModeButton, LanguageButton, SocialMediaLinks } from "..";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  marginInline: "auto",
  maxWidth: theme.breakpoints.values["lg"],
}));

const ControlBar = forwardRef(({ darkMode, onToggleDarkMode }, ref) => (
  <StyledToolbar
    ref={ref}
    sx={{ justifyContent: "space-between", minHeight: 0, py: 0.5 }}
    variant="dense"
  >
    <SocialMediaLinks socialMedias={socialMedias} />
    <Stack direction="row" spacing={1}>
      <LanguageButton language="English" onClick={() => {}} />
      <DarkModeButton darkMode={darkMode} onToggleDarkMode={onToggleDarkMode} />
    </Stack>
  </StyledToolbar>
));

ControlBar.displayName = "ControlBar";
ControlBar.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  onToggleDarkMode: PropTypes.func.isRequired,
};

export default ControlBar;
