import {
  alpha,
  Box,
  Container,
  CssBaseline,
  Link,
  styled,
  Typography,
} from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React, { useMemo, useRef, useState } from "react";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";

import { getDesignTokens, getThemedComponents } from "./assets";
import {
  CookieConsent,
  CustomHelmet,
  Drawer,
  Footer,
  Header,
  LoadingSpinner,
} from "./components";
import { useDarkMode, useGoogleAnalytics, useResizeObserver } from "./hooks";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .divider-bottom": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  "& .divider-svg": {
    position: "relative",
  },

  "& .front": {
    position: "relative",
    zIndex: 1,
  },

  "& .padding-hero": {
    margin: "auto",
    maxWidth: theme.breakpoints.values["lg"],
    padding: "7.5vh 16px",
  },

  "& .slick-dots > li > button::before": {
    color: theme.palette.text.secondary,
  },

  "& .slick-dots > li.slick-active > button::before, & .slick-prev::before, & .slick-next::before":
    {
      color: theme.palette.text.primary,
    },

  "& .text-secondary-on-primary-background": {
    color: alpha(
      theme.palette.text.secondary,
      theme.palette.mode === "dark" ? 0.94 : 0.74,
    ),
    fontWeight: 550,
  },

  "& > div.background-primary": {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none !important",
  },

  "& > div.outer > div.inner": {
    margin: "auto",
    maxWidth: theme.breakpoints.values["lg"],
    padding: `5vh ${theme.spacing(2)}`,
  },

  "& > div.outer > div.padding-half": {
    margin: "auto",
    maxWidth: theme.breakpoints.values["lg"],
    padding: `2.5vh ${theme.spacing(2)}`,
  },

  "& > div:not(.background-default), & div.background-gradient": {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.default}, ${theme.palette.background.darker})`,
  },

  "& img": {
    filter: theme.palette.mode === "dark" && "brightness(0.75)",
  },

  '& svg[id^="background-default"]': {
    path: {
      fill: theme.palette.background.default,
    },
  },

  '& svg[id^="background-primary"]': {
    path: {
      fill: theme.palette.primary.main,
    },
  },

  '& svg[id^="elevation-4"]': {
    path: {
      fill:
        theme.palette.mode === "dark" ? "#272727" : theme.palette.background,
    },
  },
}));

const StyledFooter = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: "5vh",
}));

const App = () => {
  const [darkMode, toggleDarkMode] = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { state = "loading" } = useNavigation();

  const controlBarRef = useRef(null);
  const controlBarHeight = useResizeObserver(controlBarRef);
  const appBarRef = useRef(null);
  const appBarHeight = useResizeObserver(appBarRef);

  useGoogleAnalytics();

  const theme = useMemo(
    () =>
      responsiveFontSizes(
        createTheme(
          getDesignTokens(darkMode ? "dark" : "light"),
          getThemedComponents(darkMode ? "dark" : "light"),
        ),
      ),
    [darkMode],
  );

  const renderContent = () => {
    const minHeight = `calc(100vh - ${controlBarHeight + appBarHeight}px)`;
    return state === "loading" ? (
      <LoadingSpinner sx={{ minHeight }} />
    ) : (
      <Outlet
        context={{
          appBarHeight,
          controlBarHeight,
          sx: { minHeight },
        }}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomHelmet theme={theme} />
      <CssBaseline />
      <SnackbarProvider
        preventDuplicate
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        maxSnack={3}
      >
        <Header
          appBarRef={appBarRef}
          controlBarHeight={controlBarHeight}
          controlBarRef={controlBarRef}
          darkMode={darkMode}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          toggleDarkMode={toggleDarkMode}
        />
        <Drawer open={drawerOpen} setOpen={setDrawerOpen} />
        <StyledBox>
          <ScrollRestoration />
          {renderContent()}
        </StyledBox>
        <CookieConsent
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          buttonLabel="Consent"
          message={
            <Typography sx={{ mb: 2 }}>
              We use cookies to ensure that we give you the best experience on
              our website. Read our{" "}
              <Link href="/legal/privacy-policy">Privacy Policy</Link>.
            </Typography>
          }
        />
        <StyledFooter>
          <Container maxWidth="md">
            <Footer />
          </Container>
        </StyledFooter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
